const LogoExhibit = () => {
    return (
        <svg className="w-full h-auto" xmlns="http://www.w3.org/2000/svg" width="145" height="32" viewBox="0 0 145 32" fill="none">
            <path d="M18.0177 28.5155H18.6599C18.9794 28.5155 19.2128 28.6611 19.2128 28.9697C19.2128 29.2782 18.9841 29.38 18.5597 29.38H18.0177V28.517V28.5155ZM18.4391 30.3323C19.0797 30.3323 19.1736 30.5187 19.1736 31.0857C19.1736 31.471 19.2081 31.7482 19.2425 31.8437H20.6365V31.7122C20.472 31.7122 20.4908 31.5195 20.4908 30.9525C20.4908 30.0786 20.223 29.9689 19.7782 29.7998C20.2684 29.6619 20.4846 29.2391 20.4846 28.7394C20.4846 27.8952 20.0241 27.5037 18.6239 27.5037H16.6426V31.8437H18.0177V30.3323H18.4391Z" fill="white"/>
            <path d="M24.243 29.8708L24.1913 28.8825V27.5011H25.5085V31.8427H24.2211L22.7222 29.4793L22.7739 30.4691V31.8427H21.4567V27.5011H22.7457L24.243 29.8708Z" fill="white"/>
            <path d="M28.8435 30.3668H27.9742L28.4003 28.813L28.8419 30.3668H28.8435ZM27.5561 31.8437L27.7127 31.272H29.0816L29.2445 31.8437H30.6149L29.1098 27.5037H27.7127L26.2075 31.8437H27.5561Z" fill="white"/>
            <path d="M36.6379 27.5011H35.2627V31.8412H36.6379V27.5011Z" fill="white"/>
            <path d="M39.7353 28.4486C40.343 28.4298 40.5764 29.0579 40.5764 29.6499C40.5764 30.3657 40.343 30.8967 39.7478 30.8967C39.0884 30.8967 38.9256 30.2044 38.9256 29.6499C38.9256 29.0955 39.1119 28.4486 39.7353 28.4486ZM39.7478 27.3867C38.4416 27.3867 37.5488 28.2716 37.5488 29.6358C37.5488 31.1144 38.4291 31.957 39.7478 31.957C40.9899 31.957 41.9515 31.1253 41.9515 29.6484C41.9515 28.5019 41.2467 27.3867 39.7478 27.3867Z" fill="white"/>
            <path d="M45.6003 29.8708L45.5471 28.8825V27.5011H46.8643V31.8427H45.5752L44.0779 29.4793L44.1312 30.4691V31.8427H42.814V27.5011H44.1014L45.6003 29.8708Z" fill="white"/>
            <path d="M50.1975 30.3663H49.3282L49.7527 28.8126L50.1959 30.3663H50.1975ZM48.9101 31.8432L49.0667 31.2716H50.4372L50.6 31.8432H51.9705L50.4669 27.5032H49.0667L47.5615 31.8432H48.9085H48.9101Z" fill="white"/>
            <path d="M52.7124 31.8427V27.5011H54.0876V30.8309H55.9749V31.8427H52.7124Z" fill="white"/>
            <path d="M60.2959 28.515H60.9381C61.256 28.515 61.4894 28.6606 61.4894 28.9692C61.4894 29.2777 61.2623 29.3795 60.8363 29.3795H60.2943V28.5165L60.2959 28.515ZM60.7157 30.3318C61.3547 30.3318 61.4487 30.5182 61.4487 31.0852C61.4487 31.4705 61.4831 31.7477 61.5191 31.8432H62.9131V31.7117C62.7486 31.7117 62.7659 31.519 62.7659 30.952C62.7659 30.0781 62.498 29.9684 62.0564 29.7993C62.5466 29.6615 62.7596 29.2386 62.7596 28.7389C62.7596 27.8947 62.2976 27.5032 60.902 27.5032H58.9176V31.8432H60.2943V30.3318H60.7141H60.7157Z" fill="white"/>
            <path d="M70.6793 30.0295C71.1163 30.0295 71.4374 30.053 71.4374 30.4336C71.4374 30.7594 71.1805 30.8314 70.8437 30.8314H70.2439V30.0295H70.6793ZM70.7247 28.515C71.0176 28.515 71.2839 28.5212 71.2839 28.8595C71.2839 29.1133 71.0865 29.2401 70.8124 29.2401H70.2423V28.515H70.7231H70.7247ZM70.9111 31.8432C72.0028 31.8432 72.7201 31.5065 72.7201 30.5354C72.7201 30.0655 72.4726 29.6615 72.0169 29.5409C72.2863 29.367 72.5666 29.0412 72.5666 28.5948C72.5666 27.7318 71.8148 27.5032 70.8688 27.5032H68.864V31.8432H70.9095H70.9111Z" fill="white"/>
            <path d="M75.6286 28.4486C76.2348 28.4298 76.4697 29.0579 76.4697 29.6499C76.4697 30.3657 76.2347 30.8967 75.6411 30.8967C74.9833 30.8967 74.8173 30.2044 74.8173 29.6499C74.8173 29.0955 75.0037 28.4486 75.6286 28.4486ZM75.6411 27.3867C74.3349 27.3867 73.4421 28.2716 73.4421 29.6358C73.4421 31.1144 74.3239 31.957 75.6411 31.957C76.8816 31.957 77.8448 31.1253 77.8448 29.6484C77.8448 28.5019 77.1385 27.3867 75.6411 27.3867Z" fill="white"/>
            <path d="M65.8534 28.4486C66.4595 28.4298 66.6913 29.0579 66.6913 29.6499C66.6913 30.3657 66.4595 30.8967 65.8643 30.8967C65.2065 30.8967 65.0436 30.2044 65.0436 29.6499C65.0436 29.0955 65.2284 28.4486 65.8534 28.4486ZM65.8643 27.3867C64.5581 27.3867 63.6685 28.2716 63.6685 29.6358C63.6685 31.1144 64.5471 31.957 65.8643 31.957C67.1064 31.957 68.068 31.1253 68.068 29.6484C68.068 28.5019 67.3601 27.3867 65.8643 27.3867Z" fill="white"/>
            <path d="M78.4855 27.5011H82.3322V28.6413H81.0964V31.8427H79.7212V28.6413H78.4855V27.5011Z" fill="white"/>
            <path d="M85.3651 31.8427V27.5011H88.7874V28.5145H86.7403V29.1363H88.4741V30.1496H86.7403V30.8309H88.8704V31.8427H85.3651Z" fill="white"/>
            <path d="M90.9565 31.8427H89.4106L90.8986 29.5889L89.5579 27.5011H91.1006L91.6974 28.6116L92.2957 27.5011H93.84L92.4241 29.5889L93.8525 31.8427H92.3098L91.6316 30.6258L90.9565 31.8427Z" fill="white"/>
            <path d="M94.537 31.8427V27.5011H95.9122V28.9671H97.2121V27.5011H98.5857V31.8427H97.2121V30.1606H95.9122V31.8427H94.537Z" fill="white"/>
            <path d="M99.6671 31.8427V27.5011H101.042V31.8427H99.6671Z" fill="white"/>
            <path d="M108.31 27.5011H106.935V31.8412H108.31V27.5011Z" fill="white"/>
            <path d="M109.172 27.5011H113.019V28.6413H111.784V31.8427H110.408V28.6413H109.172V27.5011Z" fill="white"/>
            <path d="M115.254 27.5011H113.879V31.8412H115.254V27.5011Z" fill="white"/>
            <path d="M118.348 28.4486C118.956 28.4298 119.186 29.0579 119.186 29.6499C119.186 30.3657 118.954 30.8967 118.359 30.8967C117.699 30.8967 117.537 30.2044 117.537 29.6499C117.537 29.0955 117.724 28.4486 118.348 28.4486ZM118.36 27.3867C117.054 27.3867 116.161 28.2716 116.161 29.6358C116.161 31.1144 117.043 31.957 118.36 31.957C119.602 31.957 120.564 31.1253 120.564 29.6484C120.564 28.5019 119.858 27.3867 118.36 27.3867Z" fill="white"/>
            <path d="M124.213 29.8708L124.16 28.8825V27.5011H125.479V31.8427H124.19L122.691 29.4793L122.746 30.4691V31.8427H121.427V27.5011H122.716L124.213 29.8708Z" fill="white"/>
            <path d="M30.5596 27.5011H34.4063V28.6413H33.1705V31.8427H31.7938V28.6413H30.5596V27.5011Z" fill="white"/>
            <path d="M103.956 30.0295C104.393 30.0295 104.714 30.053 104.714 30.4336C104.714 30.7594 104.459 30.8314 104.119 30.8314H103.518V30.0295H103.954H103.956ZM104.003 28.515C104.293 28.515 104.562 28.5212 104.562 28.8595C104.562 29.1133 104.365 29.2401 104.091 29.2401H103.519V28.515H104.003ZM104.189 31.8432C105.28 31.8432 105.997 31.5065 105.997 30.5354C105.997 30.0655 105.751 29.6615 105.297 29.5409C105.566 29.367 105.845 29.0412 105.845 28.5948C105.845 27.7318 105.093 27.5032 104.15 27.5032H102.144V31.8432H104.189Z" fill="white"/>
            <path d="M128.012 31.8621C127.99 31.364 128.178 31.0007 128.507 30.6467C129.199 29.9012 130.286 29.6083 130.286 28.8972C130.286 28.6215 130.032 28.4649 129.787 28.4649C129.317 28.4649 129.182 28.8721 129.187 29.2841H128.089C128.106 28.0279 128.772 27.5173 129.844 27.5173C130.703 27.5173 131.508 27.9058 131.508 28.9035C131.508 29.6553 130.95 29.9654 130.41 30.3131C130.109 30.5073 129.793 30.6733 129.575 30.8284H131.497V31.8605H128.012V31.8621Z" fill="white"/>
            <path d="M133.992 31.0052C133.527 31.0052 133.415 30.5024 133.415 29.7553C133.415 29.3465 133.428 28.4663 133.979 28.4663C134.584 28.4663 134.56 29.2917 134.56 29.7428C134.56 30.4601 134.426 31.0052 133.992 31.0052ZM132.194 29.6817C132.194 30.9096 132.653 31.9559 133.992 31.9559C135.331 31.9559 135.782 30.8971 135.782 29.6754C135.782 28.2533 135.065 27.5203 133.992 27.5203C133.046 27.5203 132.2 28.1092 132.194 29.6832" fill="white"/>
            <path d="M1.6298 27.5011H0.254639V31.8412H1.6298V27.5011Z" fill="white"/>
            <path d="M5.50129 29.8708L5.44804 28.8825V27.5011H6.76682V31.8427H5.4778L3.98047 29.4793L4.03216 30.4691V31.8427H2.71338V27.5011H4.0024L5.50129 29.8708Z" fill="white"/>
            <path d="M7.58228 27.5011H11.4305V28.6413H10.1948V31.8427H8.81804V28.6413H7.58228V27.5011Z" fill="white"/>
            <path d="M12.2864 31.8427V27.5011H15.7086V28.5145H13.6615V29.1363H15.3938V30.1496H13.6615V30.8309H15.7901V31.8427H12.2864Z" fill="white"/>
            <path d="M39.7353 28.4486C40.343 28.4298 40.5764 29.0579 40.5764 29.6499C40.5764 30.3657 40.343 30.8967 39.7478 30.8967C39.0884 30.8967 38.9256 30.2044 38.9256 29.6499C38.9256 29.0955 39.1119 28.4486 39.7353 28.4486ZM39.7478 27.3867C38.4416 27.3867 37.5488 28.2716 37.5488 29.6358C37.5488 31.1144 38.4291 31.957 39.7478 31.957C40.9899 31.957 41.9515 31.1253 41.9515 29.6484C41.9515 28.5019 41.2467 27.3867 39.7478 27.3867Z" fill="white"/>
            <path d="M45.6003 29.8708L45.5471 28.8825V27.5011H46.8643V31.8427H45.5752L44.0779 29.4793L44.1312 30.4691V31.8427H42.814V27.5011H44.1014L45.6003 29.8708Z" fill="white"/>
            <path d="M50.1975 30.3663H49.3282L49.7527 28.8126L50.1959 30.3663H50.1975ZM48.9101 31.8432L49.0667 31.2716H50.4372L50.6 31.8432H51.9705L50.4669 27.5032H49.0667L47.5615 31.8432H48.9085H48.9101Z" fill="white"/>
            <path d="M52.7124 31.8427V27.5011H54.0876V30.8309H55.9749V31.8427H52.7124Z" fill="white"/>
            <path d="M60.2959 28.515H60.9381C61.256 28.515 61.4894 28.6606 61.4894 28.9692C61.4894 29.2777 61.2623 29.3795 60.8363 29.3795H60.2943V28.5165L60.2959 28.515ZM60.7157 30.3318C61.3547 30.3318 61.4487 30.5182 61.4487 31.0852C61.4487 31.4705 61.4831 31.7477 61.5191 31.8432H62.9131V31.7117C62.7486 31.7117 62.7659 31.519 62.7659 30.952C62.7659 30.0781 62.498 29.9684 62.0564 29.7993C62.5466 29.6615 62.7596 29.2386 62.7596 28.7389C62.7596 27.8947 62.2976 27.5032 60.902 27.5032H58.9176V31.8432H60.2943V30.3318H60.7141H60.7157Z" fill="white"/>
            <path d="M70.6793 30.0295C71.1163 30.0295 71.4374 30.053 71.4374 30.4336C71.4374 30.7594 71.1805 30.8314 70.8437 30.8314H70.2439V30.0295H70.6793ZM70.7247 28.515C71.0176 28.515 71.2839 28.5212 71.2839 28.8595C71.2839 29.1133 71.0865 29.2401 70.8124 29.2401H70.2423V28.515H70.7231H70.7247ZM70.9111 31.8432C72.0028 31.8432 72.7201 31.5065 72.7201 30.5354C72.7201 30.0655 72.4726 29.6615 72.0169 29.5409C72.2863 29.367 72.5666 29.0412 72.5666 28.5948C72.5666 27.7318 71.8148 27.5032 70.8688 27.5032H68.864V31.8432H70.9095H70.9111Z" fill="white"/>
            <path d="M75.6286 28.4486C76.2348 28.4298 76.4697 29.0579 76.4697 29.6499C76.4697 30.3657 76.2347 30.8967 75.6411 30.8967C74.9833 30.8967 74.8173 30.2044 74.8173 29.6499C74.8173 29.0955 75.0037 28.4486 75.6286 28.4486ZM75.6411 27.3867C74.3349 27.3867 73.4421 28.2716 73.4421 29.6358C73.4421 31.1144 74.3239 31.957 75.6411 31.957C76.8816 31.957 77.8448 31.1253 77.8448 29.6484C77.8448 28.5019 77.1385 27.3867 75.6411 27.3867Z" fill="white"/>
            <path d="M65.8534 28.4486C66.4595 28.4298 66.6913 29.0579 66.6913 29.6499C66.6913 30.3657 66.4595 30.8967 65.8643 30.8967C65.2065 30.8967 65.0436 30.2044 65.0436 29.6499C65.0436 29.0955 65.2284 28.4486 65.8534 28.4486ZM65.8643 27.3867C64.5581 27.3867 63.6685 28.2716 63.6685 29.6358C63.6685 31.1144 64.5471 31.957 65.8643 31.957C67.1064 31.957 68.068 31.1253 68.068 29.6484C68.068 28.5019 67.3601 27.3867 65.8643 27.3867Z" fill="white"/>
            <path d="M78.4855 27.5011H82.3322V28.6413H81.0964V31.8427H79.7212V28.6413H78.4855V27.5011Z" fill="white"/>
            <path d="M85.3651 31.8427V27.5011H88.7874V28.5145H86.7403V29.1363H88.4741V30.1496H86.7403V30.8309H88.8704V31.8427H85.3651Z" fill="white"/>
            <path d="M90.9565 31.8427H89.4106L90.8986 29.5889L89.5579 27.5011H91.1006L91.6974 28.6116L92.2957 27.5011H93.84L92.4241 29.5889L93.8525 31.8427H92.3098L91.6316 30.6258L90.9565 31.8427Z" fill="white"/>
            <path d="M94.537 31.8427V27.5011H95.9122V28.9671H97.2121V27.5011H98.5857V31.8427H97.2121V30.1606H95.9122V31.8427H94.537Z" fill="white"/>
            <path d="M99.6671 31.8427V27.5011H101.042V31.8427H99.6671Z" fill="white"/>
            <path d="M108.31 27.5011H106.935V31.8412H108.31V27.5011Z" fill="white"/>
            <path d="M109.172 27.5011H113.019V28.6413H111.784V31.8427H110.408V28.6413H109.172V27.5011Z" fill="white"/>
            <path d="M115.254 27.5011H113.879V31.8412H115.254V27.5011Z" fill="white"/>
            <path d="M118.348 28.4486C118.956 28.4298 119.186 29.0579 119.186 29.6499C119.186 30.3657 118.954 30.8967 118.359 30.8967C117.699 30.8967 117.537 30.2044 117.537 29.6499C117.537 29.0955 117.724 28.4486 118.348 28.4486ZM118.36 27.3867C117.054 27.3867 116.161 28.2716 116.161 29.6358C116.161 31.1144 117.043 31.957 118.36 31.957C119.602 31.957 120.564 31.1253 120.564 29.6484C120.564 28.5019 119.858 27.3867 118.36 27.3867Z" fill="white"/>
            <path d="M124.213 29.8708L124.16 28.8825V27.5011H125.479V31.8427H124.19L122.691 29.4793L122.746 30.4691V31.8427H121.427V27.5011H122.716L124.213 29.8708Z" fill="white"/>
            <path d="M103.956 30.0295C104.393 30.0295 104.714 30.053 104.714 30.4336C104.714 30.7594 104.459 30.8314 104.119 30.8314H103.518V30.0295H103.954H103.956ZM104.003 28.515C104.293 28.515 104.562 28.5212 104.562 28.8595C104.562 29.1133 104.365 29.2401 104.091 29.2401H103.519V28.515H104.003ZM104.189 31.8432C105.28 31.8432 105.997 31.5065 105.997 30.5354C105.997 30.0655 105.751 29.6615 105.297 29.5409C105.566 29.367 105.845 29.0412 105.845 28.5948C105.845 27.7318 105.093 27.5032 104.15 27.5032H102.144V31.8432H104.189Z" fill="white"/>
            <path d="M128.012 31.8621C127.99 31.364 128.178 31.0007 128.507 30.6467C129.199 29.9012 130.286 29.6083 130.286 28.8972C130.286 28.6215 130.032 28.4649 129.787 28.4649C129.317 28.4649 129.182 28.8721 129.187 29.2841H128.089C128.106 28.0279 128.772 27.5173 129.844 27.5173C130.703 27.5173 131.508 27.9058 131.508 28.9035C131.508 29.6553 130.95 29.9654 130.41 30.3131C130.109 30.5073 129.793 30.6733 129.575 30.8284H131.497V31.8605H128.012V31.8621Z" fill="white"/>
            <path d="M136.529 31.8621C136.507 31.364 136.695 31.0007 137.024 30.6467C137.716 29.9012 138.803 29.6083 138.803 28.8972C138.803 28.6215 138.549 28.4649 138.303 28.4649C137.833 28.4649 137.699 28.8721 137.703 29.2841H136.605C136.623 28.0279 137.288 27.5173 138.361 27.5173C139.22 27.5173 140.025 27.9058 140.025 28.9035C140.025 29.6553 139.467 29.9654 138.927 30.3131C138.626 30.5073 138.31 30.6733 138.092 30.8284H140.014V31.8605H136.529V31.8621Z" fill="white"/>
            <path d="M136.529 31.8621C136.507 31.364 136.695 31.0007 137.024 30.6467C137.716 29.9012 138.803 29.6083 138.803 28.8972C138.803 28.6215 138.549 28.4649 138.303 28.4649C137.833 28.4649 137.699 28.8721 137.703 29.2841H136.605C136.623 28.0279 137.288 27.5173 138.361 27.5173C139.22 27.5173 140.025 27.9058 140.025 28.9035C140.025 29.6553 139.467 29.9654 138.927 30.3131C138.626 30.5073 138.31 30.6733 138.092 30.8284H140.014V31.8605H136.529V31.8621Z" fill="white"/>
            <path d="M133.992 31.0052C133.527 31.0052 133.415 30.5024 133.415 29.7553C133.415 29.3465 133.428 28.4663 133.979 28.4663C134.584 28.4663 134.56 29.2917 134.56 29.7428C134.56 30.4601 134.426 31.0052 133.992 31.0052ZM132.194 29.6817C132.194 30.9096 132.653 31.9559 133.992 31.9559C135.331 31.9559 135.782 30.8971 135.782 29.6754C135.782 28.2533 135.065 27.5203 133.992 27.5203C133.046 27.5203 132.2 28.1092 132.194 29.6832" fill="white"/>
            <path d="M142.082 30.0734V29.284H142.221C142.469 29.284 142.939 29.212 142.939 28.8564C142.939 28.6857 142.774 28.5416 142.455 28.5416C142.001 28.5416 141.98 28.7938 141.975 28.9285H140.759C140.769 28.717 140.779 28.4696 140.959 28.1908C141.274 27.6958 141.866 27.5408 142.495 27.5408C143.037 27.5408 144.079 27.7319 144.079 28.7061C144.079 29.3247 143.728 29.4892 143.517 29.5926C143.733 29.6959 144.171 29.9121 144.171 30.5715C144.171 31.1651 143.769 31.9999 142.351 31.9999C142.109 31.9999 141.263 31.9638 140.876 31.3499C140.675 31.0257 140.654 30.7265 140.644 30.4117H141.861C141.886 30.6388 141.933 30.999 142.412 30.999C142.475 30.999 142.964 30.9881 142.964 30.5448C142.964 30.1016 142.381 30.0703 142.221 30.0703H142.082V30.0734Z" fill="white"/>
            <path d="M42.5959 4.24266H51.1679V19.6404H42.5959V4.24266ZM40.093 23.5764H53.6724V0.356812H40.093V23.5748V23.5764ZM43.5701 5.43301V8.3102H45.5937V10.1521H43.5701V12.9651H45.5937V15.4789H43.5701V18.5252H50.1828V15.4789H49.727L50.3425 14.2839L49.6111 12.9651H50.1828V10.1521H47.6846V8.3102H50.1828V5.43301H43.5701ZM49.0206 12.9635L48.3659 14.2823L49.0206 15.4773H47.683V12.9635H49.0206Z" fill="white"/>
            <path d="M60.7444 7.78467L61.0326 6.97022L62.0883 8.21695L61.1986 10.6086L62.1775 13.1757H60.948V16.5008H61.9614L60.5643 19.6944V14.7466L60.2824 9.75187L60.5518 6.3077L60.746 7.78467H60.7444ZM55.7982 23.6476H57.886V19.8698H60.486L60.2041 20.5057L61.9849 23.3892L63.6247 19.6349V23.6742H66.7635V16.5024H68.3266L66.7901 19.461L68.7887 23.2639L70.4269 19.8087L68.5067 16.5024H70.0933V13.1772H69.0064L70.2233 9.43079L69.044 7.64057L70.0448 4.88242V0.205614H66.0978V3.2269L66.1119 3.26136L65.7266 4.72893V1.02633H63.4979L63.8706 0.0427246H61.7938L60.5768 3.39919V0.293324H55.7982V23.6476ZM57.886 3.92231H59.0122L58.7052 9.75187L59.0122 16.4695H57.886V3.92231ZM63.6827 3.45087L63.2128 4.99519L62.0851 3.7234L62.1916 3.45087H63.6843H63.6827ZM62.8197 6.0665L62.4923 7.14094L61.4022 5.87385L61.7437 4.78531L62.8197 6.0665ZM66.61 9.26477H64.0993L65.0986 5.80963L66.61 9.26477ZM63.6623 11.4873H67.4166V10.4598L68.607 13.1757H62.9716L63.6623 10.535V11.4873ZM66.1856 3.2269H68.4738L67.5999 5.80963L66.1856 3.2269ZM64.4141 19.1979H63.8174L63.8299 19.1744L62.0632 16.5008H64.4141V19.1979Z" fill="white"/>
            <path d="M75.4389 7.74935H81.6788V16.6237L81.0116 17.6057H75.4373V7.74935H75.4389ZM72.4426 2.24243V23.4478H82.7438L85.0243 19.3208V2.24243H72.4442H72.4426Z" fill="white"/>
            <path d="M100.626 4.39958V9.33168H95.3089V23.4404H92.131V9.33168H87.2756V4.39958H92.131V0.794086H95.3089V4.39958H100.626ZM91.8772 12.8636L89.531 21.1662L86.8026 18.9406L89.1473 10.948L91.8772 12.862V12.8636ZM98.6278 10.9496L101.074 19.0299L98.421 21.1051L95.9103 12.7289L98.6262 10.9512L98.6278 10.9496ZM98.1939 2.07527L96.9754 4.13802L95.7709 2.07527L96.9754 0L98.1939 2.07527ZM100.64 2.07527L99.4234 4.13802L98.2205 2.07527L99.4234 0L100.64 2.07527Z" fill="white"/>
            <path d="M105.527 6.79386L106.692 13.8279L103.978 15.0088L102.823 7.85107L105.527 6.79386ZM109.112 4.89087L110.279 11.7275L107.525 13.0338L106.294 5.87447L109.112 4.89244V4.89087ZM114.672 5.55026V16.6001L108.177 23.7469L106.371 19.2721L111.587 13.9516V5.55026H114.674H114.672Z" fill="white"/>
            <path d="M121.101 1.03101V7.18321L128.89 9.94293L128.237 15.7976L121.101 13.0378V23.4173H117.77V1.03101H121.101Z" fill="white"/>
            <path d="M133.36 3.27812H141.207V4.77388H133.36V3.27812ZM130.823 0.14563V12.6631L130.092 22.5194L132.631 23.4764L133.36 13.0484V7.67143H135.566V9.26744H134.059V11.6434H135.566V13.927H133.866V16.6773H134.809V20.6775L133.528 20.7746L133.628 23.6612L138.997 22.8405L138.896 20.0824L139.293 20.878L143.408 23.8492L144.05 20.5491L142.642 19.6407L143.776 17.8583L142.216 16.6773H144.008V13.927H141.907V11.6434H143.677V9.26744H141.907V7.67143H143.663V0.14563H130.826H130.823ZM139.721 9.269H137.644V7.673H139.721V9.269ZM139.721 13.9286H137.644V11.645H139.721V13.9286ZM141.113 18.7166L139.38 16.6789H142.034L141.113 18.7166ZM138.85 20.0228L136.82 20.3408V16.6773H137.154L138.85 20.0213V20.0228Z" fill="white"/>
            <path d="M0.271867 8.58924V7.40986C0.271867 2.93354 1.65956 0.394653 4.31121 0.394653C6.68251 0.394653 8.12188 2.41981 8.12188 6.62361C8.12188 9.46634 7.59563 11.0984 6.47106 13.1251L5.32927 15.0296C4.85627 15.8159 3.74893 17.722 3.59074 18.9922H8.13755V23.0739H0.254639V22.3174C0.254639 18.5694 1.60787 15.3335 3.0128 12.674L4.34724 10.2542C4.97843 9.04502 5.18987 8.07708 5.18987 6.4153C5.18987 5.26724 4.89229 4.05653 4.08254 4.17713C3.15062 4.26798 3.06291 6.35578 3.06291 7.50384V8.59238H0.271867V8.58924Z" fill="white"/>
            <path d="M15.5397 16.9937C15.5397 17.78 15.5914 19.7754 14.5906 19.8365C13.6085 19.8365 13.6085 18.3235 13.6085 16.9937V6.80529C13.6085 5.53507 13.6445 4.174 14.5906 4.174C15.6446 4.174 15.5397 6.47325 15.5397 6.80529V16.9937ZM10.6765 17.3571C10.6765 21.741 12.0971 23.6158 14.5561 23.6158C17.5586 23.6158 18.4717 21.2273 18.4717 16.267V8.10528C18.525 3.02594 17.7528 0.394653 14.5906 0.394653C12.0799 0.394653 10.6765 2.26945 10.6765 6.80529V17.3571Z" fill="white"/>
            <path d="M20.6353 8.58924V7.40986C20.6353 2.9351 22.0229 0.394653 24.6746 0.394653C27.0459 0.394653 28.4853 2.41981 28.4853 6.62361C28.4853 9.46634 27.959 11.0984 26.8344 13.1251L25.6927 15.0296C25.2196 15.8159 24.1123 17.722 23.9541 18.9922H28.5009V23.0739H20.6165V22.3174C20.6165 18.5694 21.9697 15.3335 23.3746 12.674L24.7091 10.2542C25.3402 9.04502 25.5517 8.07708 25.5517 6.4153C25.5517 5.26724 25.2541 4.05653 24.4444 4.17713C23.5124 4.26798 23.4247 6.35578 23.4247 7.50384V8.59238H20.6337L20.6353 8.58924Z" fill="white"/>
            <path d="M30.4282 7.15209V6.18415C30.4282 3.55599 31.1064 0.815063 34.2734 0.815063C36.983 0.815063 38.0809 2.87781 38.0809 6.06355V6.89366C38.0809 9.43254 37.3573 10.6119 36.4144 11.1288V11.1742C37.4701 11.702 38.4129 12.8391 38.4129 15.5487V17.2967C38.4129 20.9899 37.3667 23.3345 34.2405 23.3345C31.1142 23.3345 30.2622 20.9836 30.2622 17.8668V17.0273H33.2067V17.9639C33.2067 19.0117 33.3994 19.9248 34.2248 19.9248C35.0502 19.9248 35.2241 18.9506 35.2241 17.8135V15.5315C35.2241 13.7554 34.8341 13.0615 33.5748 13.0615H32.6962V9.52965H33.6202C34.6493 9.52965 35.0189 9.02062 35.0189 7.34474V6.04005C35.0189 4.98754 34.903 4.16056 34.1747 4.16056C33.4464 4.16056 33.2804 4.93272 33.2804 5.95391V7.14896H30.4282V7.15209Z" fill="white"/>
        </svg>
    );
};

export default LogoExhibit;