import Background from "./components/Background";

import Light from "./images/Light";
import Logo from "./images/Logo";
import LogoExhibit from "./images/LogoExhibit";
import LogoIrex from "./images/LogoIrex";
import LogoITA from "./images/LogoITA";
import LogoPMC from "./images/LogoPMC";
import LogoTAIROA from "./images/LogoTAIROA";
import LogoTETDC from "./images/LogoTETDC";
import Texts from "./images/Texts";

const company_list_1 = [
    {
        index: "1",
        order: "精密伝動機構、特許取得済みのモーター構造",
        title: "Tricore Corporation",
        image: "/images/pic_company_1.png",
        link: "https://pavilion.twmt.tw/electric-vehicle/line-4/sb-2297sg",
        scale: 1.2
    },
    {
        index: "2",
        order: "協働ロボット",
        title: "Techman Robot",
        image: "/images/pic_company_2.png",
        link: "https://pavilion.twmt.tw/electric-vehicle/line-4/tm5",
        scale: 0.9
    },
    {
        index: "3",
        order: "産業用ロボット、垂直多関節ロボット",
        title: "HIWIN Technologies Corp.",
        image: "/images/pic_company_5.png",
        link: "https://pavilion.twmt.tw/electric-vehicle/line-4/intelligent-40-ballscrew"
    },
    {
        index: "4",
        order: "3Dカメラ製品、3D×AIエッジアクセラレーター",
        title: "LIPS Corporation",
        image: "/images/pic_company_4.png",
        link: "https://pavilion.twmt.tw/electric-vehicle/line-4/lipsedgetm-s-series-high-resolution-3d-stereo-camera-with-edge-ai-enabled",
        scale: 0.85
    }
];

const company_list_2 = [
    {
        index: "5",
        order: "T-Skinを装着したロボットはロボット表面のセンサーにより予期せぬ接触から保護し、産業界における安全基準を満足させる事ができます。",
        title: "Mechavision Inc.",
        image: "/images/pic_company_3.png",
        link: "https://pavilion.twmt.tw/aerospace/line-1/t-skin",
        scale: 0.85
    },
    {
        index: "6",
        order: "ロボットコントローラー、ロボット セーフティ コントローラ",
        title: "NexCOBOT Co.,Ltd.",
        image: "/images/pic_company_6.png",
        link: "https://pavilion.twmt.tw/aerospace/line-1/grc-200c"
    },
    {
        index: "7",
        order: "3 軸力センサー",
        title: "DMP Electronics Inc",
        image: "/images/pic_company_7.png",
        link: "https://pavilion.twmt.tw/aerospace/line-1/the-86sense-rm-320-is-a-3-axes-force-sensor"
    },
    {
        index: "8",
        order: "AIコンピューティングPC",
        title: "Vecow Co., Ltd",
        image: "/images/pic_company_8.png",
        link: "https://pavilion.twmt.tw/aerospace/line-1/vecow-aic-100-arm-based-iot-system"
    }
];

const App = () => {
    return (
        <>
            <Background />

            <main className="relative block  overflow-x-hidden">

                <nav className="fixed z-[10] top-0 left-0 w-full px-[20px] py-[15px]">
                    <Logo />
                </nav>

                <div className="mt-[92px] flex items-center justify-center md:mt-[82px]">
                    <div className="w-[145px] md:w-[206px]">
                        <LogoExhibit />
                    </div>
                    <div className="ml-[25px] w-[88px] md:w-[126px]">
                        <LogoIrex />
                    </div>
                </div>

                <h1 className="mt-[25px] font-Inter font-bold text-center text-[#7FC3C8] text-[32px] leading-snug md:text-[48px]">Robot Key Technology<br/>Integration Solution</h1>

                <h2 className="my-[24px] mx-auto max-w-[350px] md:max-w-[525px]">
                    <Texts />
                </h2>

                <h3 className="font-medium text-[14px] text-[#7FC3C8] text-center font-Inter leading-normal">Venue</h3>
                <p className="font-semibold text-[14px] text-[#FFFFFF] text-center font-Inter leading-normal">Booth E4-10, East Hall 4, Big Sight, Tokyo, Japan</p>

                <h3 className="mt-[15px] font-medium text-[14px] text-[#7FC3C8] text-center font-Inter leading-normal">Business Matching</h3>
                <p className="font-semibold text-[14px] text-[#FFFFFF] text-center font-Inter leading-normal">No29-De2, 2023</p>

                <h3 className="mt-[15px] font-bold text-[14px] text-[#7FC3C8] text-center font-Inter leading-normal">Product Launch</h3>
                <p className="font-bold text-[20px] text-[#FFFFFF] text-center font-Inter leading-normal">No30, 2023</p>


                <div className="relative mb-[65px] pb-[40px] pointer-events-none">
                    <div className="w-[330px] mx-auto translate-x-[12px] md:w-[660px] md:translate-x-[24px] radius">
                        <div className="floating w-full">
                            <Light />
                            <img className="relative w-full h-auto glow" src="/images/pic_earth.png" alt="pic-earth"/>
                        </div>
                    </div>

                    <div className="absolute w-[388px] left-[50%] bottom-0 translate-x-[-50%] md:w-[800px]">
                        <img className="relative w-full h-auto" src="/images/pic_hand.png" alt="pic-hand"/>
                    </div>
                </div>

                <h2 className="mt-[40px] font-bold text-[36px] text-[#90DFF0] text-center font-Inter leading-normal text_glow lg:text-[48px]">
                    <a className="inline-block" target="_blank" rel="noreferrer" href="https://pavilion.twmt.tw/electric-vehicle/line-4">電動スマート工場</a>
                </h2>

                <div className="mx-auto px-[30px] md:flex md:flex-wrap md:flex-row md:items-start md:max-w-[1252px] md:justify-start">
                    {company_list_1.map(data => (
                        <div key={data.title} className="unit relative mx-auto mt-[45px] flex flex-col justify-center md:w-[50%] md:px-[20px] lg:w-[25%]">
                            <a onClick={() => {
                                if (window.gtag) {
                                    window.gtag('event', 'company_link', { 'company_name': data.title });
                                }
                            }} className="relative pt-[72px]" data-index={data.index} href={data.link} target="_blank" rel="noreferrer">
                                <div className="unit_image mx-auto w-[168px] h-[168px] bg-[#FFFFFF] flex items-center justify-center flex-none rounded-[50%] overflow-hidden">
                                    <img src={data.image} alt={data.title} style={{ transform: `scale(${data.scale})` }}/>
                                </div>
                                <div className="w-full">
                                    <p className="max-w-[310px] mx-auto mt-[10px] font-semibold text-[24px] text-[#FFFFFF] text-center font-Inter leading-normal lg:mt-[15px]">{data.title}</p>
                                    <h4 className="mt-[10px] text-[20px] text-[#83F0F8] text-center font-Inter leading-normal font-normal">{data.order}</h4>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>

                <h2 className="mt-[40px] font-bold text-[36px] text-[#90DFF0] text-center font-Inter leading-normal text_glow lg:text-[48px] lg:mt-[80px]">
                    <a className="inline-block" target="_blank" rel="noreferrer" href="https://pavilion.twmt.tw/aerospace/line-1">航空宇宙スマート工場 (機内扉)</a>
                </h2>

                <div className="mx-auto px-[30px] md:flex md:flex-wrap md:flex-row md:items-start md:max-w-[1252px] md:justify-start">
                    {company_list_2.map(data => (
                        <div key={data.title} className="unit order relative mx-auto mt-[45px] flex flex-col justify-center md:w-[50%] md:px-[20px] lg:w-[25%]">
                            <a onClick={() => {
                                if (window.gtag) {
                                    window.gtag('event', 'company_link', { 'company_name': data.title });
                                }
                            }} className="relative pt-[72px]" data-index={data.index} href={data.link} target="_blank" rel="noreferrer">
                                <div className="unit_image mx-auto w-[168px] h-[168px] bg-[#FFFFFF] flex items-center justify-center flex-none rounded-[50%] overflow-hidden">
                                    <img src={data.image} alt={data.title} style={{ transform: `scale(${data.scale})` }}/>
                                </div>
                                <div className="w-full">
                                    <p className="max-w-[310px] mx-auto mt-[10px] font-semibold text-[24px] text-[#FFFFFF] text-center font-Inter leading-normal lg:mt-[15px]">{data.title}</p>
                                    <h4 className="mt-[10px] text-[20px] text-[#83F0F8] text-center font-Inter leading-normal font-normal">{data.order}</h4>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>

                <footer className="mt-[100px] p-[50px] bg-[#0D1549] lg:py-[50px]">
                    <div className="mx-auto md:flex md:flex-wrap md:justify-between lg:max-w-[1240px]">
                        <div className="md:w-[calc(100%-300px)] lg:w-auto lg:mt-0 lg:mb-[20px]">
                            <div className="w-[160px]">
                                <LogoIrex />
                            </div>
                        </div>
                        <div className="mt-[50px] md:mt-0 md:w-[300px] lg:w-auto lg:mt-0 lg:mb-[20px]">
                            <p className="mb-[24px] font-Baloo text-[20px] text-white">Organized by:</p>
                            <LogoITA />
                        </div>
                        <div className="mt-[50px] md:mt-0 md:w-[calc(100%-300px)] lg:w-auto lg:mt-0 lg:mb-[20px]">
                            <p className="mb-[24px] font-Baloo text-[20px] text-white">Implemented by:</p>
                            <div className="lg:flex">
                                <LogoPMC />

                                <div className="mt-[30px] lg:mt-0 lg:ml-[30px]">
                                    <LogoTETDC />
                                </div>
                            </div>
                        </div>
                        <div className="mt-[50px] md:w-[300px] lg:w-auto lg:mt-0 lg:mb-[20px]">
                            <p className="mb-[24px] font-Baloo text-[20px] text-white">Partner:</p>
                            <LogoTAIROA />
                        </div>
                    </div>
                    <div className="mt-[50px] md:text-center lg:mt-[65px]">
                        <div className="border inline-block text-white text-[20px] font-bold font-Baloo leading-normal px-[11px] py-[4px]">
                            AD by TITA
                        </div>
                    </div>
                </footer>
            </main>
        </>
    );
};

export default App;