import * as THREE from "three";

class Scene {
    constructor({ canvas }) {

        this.canvas = canvas;
        this.scene = new THREE.Scene();
        this.time = new THREE.Clock();

        this.camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 0.1, 100000);
        this.camera.position.set(0, 0, 21);

        this.renderer = new THREE.WebGLRenderer({
            alpha: true,
            antialias: true,
            canvas: canvas,
            transmission: true
        });
        this.renderer.setClearColor(0x000000, 1);
        this.renderer.setSize(window.innerWidth, window.innerHeight);

        this.uniforms = {
            u_resolution: { value: new THREE.Vector2(window.innerWidth, window.innerHeight) },
            u_time: { value: 1 }
        };


        this.init();
        this.animate();

        this.resize();
        window.addEventListener("resize", this.resize, false);
    }

    init = () => {
        this.setUpBackground();
    }

    resize = () => {
        this.uniforms["u_resolution"].value.set(window.innerWidth, window.innerHeight);

        this.camera.aspect = window.innerWidth / window.innerHeight;
        this.camera.updateProjectionMatrix();
        this.renderer.setSize(window.innerWidth, window.innerHeight);
    }

    setUpBackground = () => {
        const geometry = new THREE.PlaneGeometry(64, 36);
        const material = new THREE.ShaderMaterial({
            uniforms: this.uniforms,
            vertexShader: `
                varying vec2 vUv;

                void main() {
                    vUv = uv;
                    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
                }
            `,
            fragmentShader: `
                uniform vec2 u_resolution;
                uniform float u_time;

                vec3 mod289(vec3 x) { return x - floor(x * (1.0 / 289.0)) * 289.0; }
                vec2 mod289(vec2 x) { return x - floor(x * (1.0 / 289.0)) * 289.0; }
                vec3 permute(vec3 x) { return mod289(((x * 34.0) + 1.0) * x); }

                float snoise(vec2 v) {
                    const vec4 C = vec4(0.211324865405187,  // (3.0-sqrt(3.0))/6.0
                                        0.366025403784439,  // 0.5*(sqrt(3.0)-1.0)
                                        -0.577350269189626,  // -1.0 + 2.0 * C.x
                                        0.024390243902439);
                    vec2 i = floor(v + dot(v, C.yy));
                    vec2 x0 = v - i + dot(i, C.xx);
                    vec2 i1;

                    i1 = (x0.x > x0.y) ? vec2(1.0, 0.0) : vec2(0.0, 1.0);
                    vec4 x12 = x0.xyxy + C.xxzz;
                    x12.xy -= i1;
                    i = mod289(i);
                    vec3 p = permute(permute(i.y + vec3(0.0, i1.y, 1.0 )) + i.x + vec3(0.0, i1.x, 1.0));

                    vec3 m = max(0.5 - vec3(dot(x0, x0), dot(x12.xy, x12.xy), dot(x12.zw, x12.zw)), 0.0);
                    m = m*m ;
                    m = m*m ;
                    vec3 x = 2.0 * fract(p * C.www) - 1.0;
                    vec3 h = abs(x) - 0.5;
                    vec3 ox = floor(x + 0.5);
                    vec3 a0 = x - ox;
                    m *= 1.79284291400159 - 0.85373472095314 * ( a0*a0 + h*h );
                    vec3 g;
                    g.x  = a0.x  * x0.x  + h.x  * x0.y;
                    g.yz = a0.yz * x12.xz + h.yz * x12.yw;
                    return 130.0 * dot(m, g);
                }

                void main() {
                    vec2 uv = gl_FragCoord.xy / u_resolution.xy;

                    uv.x *= u_resolution.x / u_resolution.y;
                    vec3 color = vec3(0.0);
                    vec2 pos = vec2(uv * 1.5);

                    float DF = 0.0;

                    float a = 0.0;
                    vec2 vel = vec2(u_time * 0.1);
                    DF += snoise(pos + vel) * 0.25 + 0.25;

                    a = snoise(pos * vec2(cos(u_time * 0.15), sin(u_time * 0.1)) * 0.1) * 3.1415;
                    vel = vec2(cos(a), sin(a));
                    DF += snoise(pos + vel) * 0.25 + 0.25;

                    color = vec3(smoothstep(0.2, 0.7, fract(DF)));





                    // uv *= 4.0;

                    // float len;

                    // for (int i = 0; i < 2; i++) {
                    //     len = length(uv);
                    //     uv.x += sin(uv.y + u_time / 2.0 * 0.8) * 2.0;
                    //     uv.y += cos(uv.x + u_time / 2.0 * 0.5 + cos(len * 0.5)) * 2.0;
                    // }






                    vec3 finalColor = mix(
                        vec3(39.0 / 255.0, 41.0 / 255.0, 85.0 / 255.0),
                        vec3(104.0 / 255.0, 53.0 / 255.0, 121.0 / 255.0),
                        1.0 - color
                    );

                    gl_FragColor = vec4(finalColor, 1.0);
                    // gl_FragColor = vec4(color, 1.0);
                }
            `
        });
        const mesh = new THREE.Mesh(geometry, material);
        mesh.position.z = -10;
        this.scene.add(mesh);
    }

    render = () => {
        this.renderer.render(this.scene, this.camera);
    }

    animate = () => {
        const getTime = this.time.getElapsedTime();
        this.uniforms.u_time.value = getTime;

        // this.stats.begin();
        this.render();
        // this.stats.end();

        requestAnimationFrame(this.animate);
    }
};

export default Scene;