const Light = () => {
    return (
        <svg className="absolute w-[132%] h-auto top-[50%] left-[50%] translate-x-[calc(-50%-24px)] translate-y-[-50%] shiny" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 896 883">
            <linearGradient id="SVGID_1_" x1="447.495" x2="446.672" y1="432.14" y2="92.394" gradientTransform="matrix(1 0 0 -1 0 884)" gradientUnits="userSpaceOnUse">
                <stop offset="0" stopColor="#042069" stopOpacity="0"/>
                <stop offset=".625" stopColor="#24c1e3" stopOpacity=".3"/>
                <stop offset="1" stopColor="#0074b6" stopOpacity="0"/>
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M446.7 451.9l47.1 336.9h-94.2l47.1-336.9z"/>
            <linearGradient id="SVGID_00000065071126617075793960000018200928097774520709_" x1="450.455" x2="315.575" y1="436.677" y2="288.662" gradientTransform="matrix(1 0 0 -1 0 884)" gradientUnits="userSpaceOnUse">
                <stop offset="0" stopColor="#042069" stopOpacity="0"/>
                <stop offset=".625" stopColor="#24c1e3" stopOpacity=".3"/>
                <stop offset="1" stopColor="#0074b6" stopOpacity="0"/>
            </linearGradient>
            <path fill="url(#SVGID_00000065071126617075793960000018200928097774520709_)" d="M450.4 447.2l-53.7 219.6-160-145.5 213.7-74.1z"/>
            <linearGradient id="SVGID_00000178200641389430919780000006207712693532453289_" x1="447.89" x2="535.766" y1="435.906" y2="192.39" gradientTransform="matrix(1 0 0 -1 0 884)" gradientUnits="userSpaceOnUse">
                <stop offset="0" stopColor="#042069" stopOpacity="0"/>
                <stop offset=".625" stopColor="#24c1e3" stopOpacity=".3"/>
                <stop offset="1" stopColor="#0074b6" stopOpacity="0"/>
            </linearGradient>
            <path fill="url(#SVGID_00000178200641389430919780000006207712693532453289_)" d="M447.4 448.3l131.9 225.3-88.6 32.1-43.3-257.4z"/>
            <linearGradient id="SVGID_00000163055381366528813250000000632395621873013419_" x1="460.827" x2="819.612" y1="448.756" y2="249.825" gradientTransform="matrix(1 0 0 -1 0 884)" gradientUnits="userSpaceOnUse">
                <stop offset="0" stopColor="#042069" stopOpacity="0"/>
                <stop offset=".625" stopColor="#24c1e3" stopOpacity=".3"/>
                <stop offset="1" stopColor="#0074b6" stopOpacity="0"/>
            </linearGradient>
            <path fill="url(#SVGID_00000163055381366528813250000000632395621873013419_)" d="M459.7 437.3L827.9 612l-22.5 41.1-345.7-215.8z"/>
            <linearGradient id="SVGID_00000171679326489666602450000007370428350324891545_" x1="444.024" x2="203.236" y1="449.569" y2="544.664" gradientTransform="matrix(1 0 0 -1 0 884)" gradientUnits="userSpaceOnUse">
                <stop offset="0" stopColor="#042069" stopOpacity="0"/>
                <stop offset=".625" stopColor="#24c1e3" stopOpacity=".3"/>
                <stop offset="1" stopColor="#0074b6" stopOpacity="0"/>
            </linearGradient>
            <path fill="url(#SVGID_00000171679326489666602450000007370428350324891545_)" d="M444.2 434l-257.5-46.8 37-94.2 220.5 141z"/>
            <linearGradient id="SVGID_00000163036937099953459470000000873712186916955833_" x1="445.079" x2="648.486" y1="444.945" y2="575.151" gradientTransform="matrix(1 0 0 -1 0 884)" gradientUnits="userSpaceOnUse">
                <stop offset="0" stopColor="#042069" stopOpacity="0"/>
                <stop offset=".625" stopColor="#24c1e3" stopOpacity=".3"/>
                <stop offset="1" stopColor="#0074b6" stopOpacity="0"/>
            </linearGradient>
            <path fill="url(#SVGID_00000163036937099953459470000000873712186916955833_)" d="M445.4 439.5l183.3-157.7 36.2 56.2-219.5 101.5z"/>
            <linearGradient id="SVGID_00000116205272749761575500000013331897802925012154_" x1="446.341" x2="266.846" y1="438.72" y2="727.181" gradientTransform="matrix(1 0 0 -1 0 884)" gradientUnits="userSpaceOnUse">
                <stop offset="0" stopColor="#042069" stopOpacity="0"/>
                <stop offset=".625" stopColor="#24c1e3" stopOpacity=".3"/>
                <stop offset="1" stopColor="#0074b6" stopOpacity="0"/>
            </linearGradient>
            <path fill="url(#SVGID_00000116205272749761575500000013331897802925012154_)" d="M446.8 445L205.6 198.4 331 120.1 446.8 445z"/>
            <linearGradient id="SVGID_00000082354689661928027210000017759296319838604710_" x1="442.199" x2="231.34" y1="445.586" y2="395.631" gradientTransform="matrix(1 0 0 -1 0 884)" gradientUnits="userSpaceOnUse">
                <stop offset="0" stopColor="#042069" stopOpacity="0"/>
                <stop offset=".625" stopColor="#00d1ff" stopOpacity=".3"/>
                <stop offset="1" stopColor="#0074b6" stopOpacity="0"/>
            </linearGradient>
            <path fill="url(#SVGID_00000082354689661928027210000017759296319838604710_)" d="M442.2 438.2l-192 121.7L216 416l226.2 22.2z"/>
            <linearGradient id="SVGID_00000027604900296384699370000017827348145269801627_" x1="441.414" x2="757.902" y1="439.856" y2="563.401" gradientTransform="matrix(1 0 0 -1 0 884)" gradientUnits="userSpaceOnUse">
                <stop offset="0" stopColor="#042069" stopOpacity="0"/>
                <stop offset=".625" stopColor="#00d1ff" stopOpacity=".3"/>
                <stop offset="1" stopColor="#0074b6" stopOpacity="0"/>
            </linearGradient>
            <path fill="url(#SVGID_00000027604900296384699370000017827348145269801627_)" d="M441.5 444.5l274.8-222.3L794.2 421l-352.7 23.5z"/>
            <linearGradient id="SVGID_00000160875257756257009370000008231943405045943206_" x1="450.38" x2="166.401" y1="448.036" y2="634.537" gradientTransform="matrix(1 0 0 -1 0 884)" gradientUnits="userSpaceOnUse">
                <stop offset="0" stopColor="#042069" stopOpacity="0"/>
                <stop offset=".625" stopColor="#00d1ff" stopOpacity=".3"/>
                <stop offset="1" stopColor="#0074b6" stopOpacity="0"/>
            </linearGradient>
            <path fill="url(#SVGID_00000160875257756257009370000008231943405045943206_)" d="M451 435L150.7 278.7l36.1-55.3L451 435z"/>
            <linearGradient id="SVGID_00000160881454096279560030000001311772081941971588_" x1="449.769" x2="685.307" y1="434.32" y2="679.166" gradientTransform="matrix(1 0 0 -1 0 884)" gradientUnits="userSpaceOnUse">
                <stop offset="0" stopColor="#042069" stopOpacity="0"/>
                <stop offset=".625" stopColor="#24c1e3" stopOpacity=".3"/>
                <stop offset="1" stopColor="#0074b6" stopOpacity="0"/>
            </linearGradient>
            <path fill="url(#SVGID_00000160881454096279560030000001311772081941971588_)" d="M450.6 450.5L659.5 184l47.8 45.6-256.7 220.9z"/>
        </svg>
    );
};

export default Light;