const LogoIrex = () => {
    return (
        <svg className="w-full h-auto" xmlns="http://www.w3.org/2000/svg" width="88" height="32" viewBox="0 0 88 32" fill="none">
            <path d="M0.763075 31.9988H0.171387V29.7543L0.769565 29.7564L0.763075 31.9988Z" fill="white"/>
            <path d="M3.83996 31.9988H3.25368L1.67332 30.187V31.9988H1.03296V29.7564H1.91779L3.2115 31.2914L3.21366 29.7564L3.84213 29.7543L3.83996 31.9988Z" fill="white"/>
            <path d="M6.92511 30.146L5.82069 30.1503V31.999H5.229V30.1503L4.1062 30.146V29.7566H6.92835L6.92511 30.146Z" fill="white"/>
            <path d="M81.551 0.797949L75.5346 8.36875C75.3897 8.55696 75.3183 8.89337 75.4145 9.13134L78.3989 17.4409C78.4931 17.6811 78.3514 17.8444 78.1112 17.8444H73.766C73.5291 17.8444 73.2868 17.6811 73.1927 17.4409L71.8092 13.6453C71.7151 13.4051 71.5464 13.3813 71.4025 13.576L68.2786 17.5113C68.112 17.7027 67.7972 17.8444 67.5571 17.8444H63.2152C62.9772 17.8444 62.9025 17.7038 63.0486 17.5113L69.78 9.05887C69.9249 8.86849 69.9974 8.53533 69.9 8.29627L67.2272 0.870423C67.132 0.63245 67.2758 0.442071 67.5138 0.442071H71.859C72.097 0.442071 72.336 0.63245 72.4312 0.870423L73.4826 3.78343C73.5811 4.02141 73.7693 4.0452 73.9099 3.85266L76.348 0.796868C76.4941 0.607571 76.8034 0.437744 77.0392 0.437744H81.4104C81.6484 0.437744 81.7198 0.607571 81.5521 0.796868" fill="white"/>
            <path d="M7.8324 27.9602H0.929012C0.552582 27.9602 0.326507 27.6595 0.435758 27.2809L5.42563 8.67137C5.50243 8.3328 5.91456 8.03101 6.29423 8.03101H13.1565C13.5308 8.03101 13.7579 8.3328 13.6844 8.67137L8.69668 27.2798C8.58527 27.6584 8.20775 27.9591 7.8324 27.9591" fill="white"/>
            <path d="M7.8324 27.9602H0.929012C0.552582 27.9602 0.326507 27.6595 0.435758 27.2809L5.42563 8.67137C5.50243 8.3328 5.91456 8.03101 6.29423 8.03101H13.1565C13.5308 8.03101 13.7579 8.3328 13.6844 8.67137L8.69668 27.2798C8.58527 27.6584 8.20775 27.9591 7.8324 27.9591" fill="white"/>
            <path d="M13.5917 6.59939H6.69043C6.314 6.59939 6.08468 6.29868 6.2015 5.92117L7.45519 1.10762C7.52983 0.769045 7.94628 0.465088 8.32271 0.465088H15.185C15.5658 0.465088 15.7864 0.769045 15.7139 1.10762L14.4624 5.92117C14.3478 6.29868 13.9692 6.59939 13.5927 6.59939" fill="white"/>
            <path d="M43.4167 0.640365L38.7027 18.2169C38.628 18.5554 38.2116 18.8583 37.8384 18.8583H35.6512C35.2726 18.8583 35.0844 19.159 35.2348 19.4976L38.2516 26.852C38.402 27.1917 38.2127 27.4935 37.8395 27.4935H30.935C30.5586 27.4935 30.141 27.1917 29.9918 26.852L26.9749 19.4976C26.8256 19.159 26.4081 18.8583 26.0306 18.8583H24.0738C23.6963 18.8583 23.3534 19.159 23.2431 19.5354L21.2809 26.8153C21.1662 27.1928 20.7919 27.4946 20.4144 27.4946H13.5099C13.1335 27.4946 12.9074 27.1928 13.0221 26.8153L20.0337 0.640365C20.1105 0.302875 20.5226 0 20.9012 0H42.9256C43.3021 0 43.4903 0.302875 43.4156 0.640365M27.0874 5.12832L24.977 13.0496C24.9024 13.3892 25.0917 13.691 25.4659 13.691H30.9739C31.3504 13.691 31.7614 13.3903 31.8404 13.0496L33.9908 5.12832C34.1011 4.75189 33.8394 4.45118 33.4629 4.45118H27.956C27.5796 4.45118 27.201 4.75081 27.0885 5.12832" fill="white"/>
            <path d="M62.1416 3.28373H53.2803C53.0445 3.28373 52.8022 3.47519 52.7308 3.71208L51.753 7.34226C51.7054 7.5586 51.8503 7.74789 52.0894 7.74789H60.1848C60.4217 7.74789 60.611 7.96315 60.611 8.20221V10.1579C60.611 10.4002 60.4217 10.5906 60.1848 10.5906H51.3225C51.0845 10.5906 50.8249 10.7821 50.7762 10.9951L49.9163 14.1699C49.8438 14.409 50.0104 14.6004 50.2505 14.6004H58.1523C58.3924 14.6004 58.5828 14.793 58.5828 15.0299V17.4172C58.5828 17.6573 58.3924 17.8726 58.1523 17.8726H44.1357C43.8988 17.8726 43.7571 17.6811 43.8274 17.4399L48.2926 0.870463C48.337 0.658451 48.6009 0.463745 48.8421 0.463745H62.1416C62.3796 0.463745 62.5689 0.657369 62.5689 0.896424V2.85321C62.5689 3.09335 62.3796 3.28373 62.1416 3.28373Z" fill="white"/>
            <path d="M50.3607 24.6304H46.2762C46.1659 24.6304 46.0544 24.7202 46.022 24.8306L45.625 26.306C45.5904 26.4163 45.6564 26.5083 45.7656 26.5083H49.0302C49.1405 26.5083 49.2292 26.5959 49.2292 26.7051V27.816C49.2292 27.9264 49.1405 28.0129 49.0302 28.0129L42.9305 28.0259C42.8158 28.0259 42.7499 27.9361 42.7834 27.8258L43.9495 23.5217C43.9808 23.4114 44.0922 23.3238 44.2047 23.3238H48.2968C48.4082 23.3238 48.5207 23.234 48.5553 23.1236L48.9977 21.4362C49.0205 21.3367 48.9642 21.2469 48.8528 21.2469H45.559C45.4454 21.2469 45.3589 21.1604 45.3589 21.05V20.1371C45.3589 20.0278 45.4454 19.9261 45.559 19.9261H51.6252C51.7377 19.9261 51.8134 20.0159 51.7809 20.1252L50.6149 24.4411C50.5943 24.5417 50.4721 24.6315 50.3618 24.6315" fill="white"/>
            <path d="M55.1938 19.9247H61.697C61.8073 19.9247 61.883 20.0145 61.8516 20.1237L59.777 27.8243C59.7542 27.9238 59.6342 28.0115 59.5184 28.0115H53.0088C52.8984 28.0115 52.8422 27.9238 52.866 27.8243L54.9385 20.1237C54.9753 20.0134 55.0856 19.9247 55.196 19.9247M55.8493 26.5057H57.5162C57.6265 26.5057 57.7347 26.4149 57.7704 26.3035L59.0803 21.4326C59.1117 21.3212 59.0327 21.2433 58.9213 21.2433H57.2577C57.1473 21.2433 57.0381 21.3212 57.0013 21.4326L55.6935 26.3035C55.66 26.4138 55.7401 26.5057 55.8493 26.5057Z" fill="white"/>
            <path d="M7.77702 31.999C7.61261 31.999 7.1875 31.9449 7.1875 31.4992V30.2574C7.1875 29.8128 7.61261 29.7566 7.77702 29.7566H10.0086L10.0064 30.1471L7.88952 30.1503C7.83111 30.1503 7.77919 30.2001 7.77919 30.2574V30.6284H10.0064V31.0179H7.77702L7.77919 31.5035C7.77919 31.5609 7.83219 31.6117 7.88952 31.6117L10.0064 31.6074L10.0086 31.999H7.77702Z" fill="white"/>
            <path d="M12.5097 30.5146C12.5097 30.5773 12.4599 30.6249 12.3982 30.6249L10.8666 30.6282V30.1457L12.3982 30.149C12.4599 30.149 12.5097 30.1987 12.5097 30.2561V30.5146ZM13.1024 30.5146L13.1003 30.2561C13.0927 29.8115 12.6762 29.7552 12.5129 29.7552H10.2803V31.9987H10.8666L10.8633 31.0176L12.3993 31.0133C12.461 31.0133 12.5107 31.0652 12.5107 31.1279V31.9998H13.1013V31.1279C13.1013 31.0089 13.0505 30.8921 12.9705 30.8305C13.0527 30.7601 13.1067 30.6509 13.1046 30.5157" fill="white"/>
            <path d="M16.1726 31.9988H15.5885L14.0059 30.187V31.9988H13.3645V29.7564H14.2515L15.5441 31.2914V29.7564L16.1748 29.7543L16.1726 31.9988Z" fill="white"/>
            <path d="M18.6683 30.6252L17.0252 30.6284L17.0285 30.2574C17.0285 30.1947 17.0782 30.1471 17.1388 30.1471L18.5569 30.1503C18.6196 30.1503 18.664 30.2001 18.664 30.2574L18.6683 30.6252ZM19.2578 31.999V30.2574C19.2578 29.8128 18.8316 29.7566 18.6683 29.7566H17.0252C16.8586 29.7566 16.4368 29.8128 16.4368 30.2574V31.999H17.0285V31.0179L18.6629 31.0135V31.999H19.2568H19.2578Z" fill="white"/>
            <path d="M22.3397 30.146L21.2342 30.1503L21.2352 31.999H20.6425V30.1503L19.5208 30.146V29.7566H22.3429L22.3397 30.146Z" fill="white"/>
            <path d="M23.1982 31.9988H22.6033V29.7543L23.2004 29.7564L23.1982 31.9988Z" fill="white"/>
            <path d="M25.6952 31.5055C25.6952 31.565 25.6433 31.617 25.5849 31.617H24.1744C24.1051 31.617 24.0565 31.565 24.0565 31.5055V30.2464C24.0565 30.188 24.1051 30.1361 24.1744 30.1361H25.5849C25.6433 30.1361 25.6952 30.1869 25.6952 30.2464V31.5055ZM26.2815 31.498V30.2519C26.2815 29.7792 25.8434 29.7543 25.6844 29.7543H24.0684C23.9104 29.7543 23.468 29.7781 23.468 30.2519V31.498C23.468 31.975 23.9104 31.9999 24.0684 31.9999H25.6844C25.8434 31.9999 26.2815 31.975 26.2815 31.498Z" fill="white"/>
            <path d="M29.3463 31.9988H28.7633L27.184 30.187V31.9988H26.5415V29.7564H27.4296L28.7222 31.2914L28.7265 29.7564L29.3528 29.7543L29.3463 31.9988Z" fill="white"/>
            <path d="M31.8491 30.6252L30.2038 30.6284L30.2092 30.2574C30.2092 30.1947 30.2579 30.1471 30.3174 30.1471L31.7366 30.1503C31.7993 30.1503 31.8458 30.2001 31.8458 30.2574L31.8501 30.6252H31.8491ZM32.4343 31.999V30.2574C32.4343 29.8128 32.0124 29.7566 31.8491 29.7566H30.2038C30.0405 29.7566 29.6154 29.8128 29.6154 30.2574V31.999H30.2092V31.0146L31.8447 31.0124V31.9979H32.4353L32.4343 31.999Z" fill="white"/>
            <path d="M33.2867 31.9987C33.1201 31.9987 32.7004 31.9446 32.7004 31.4968V29.7552H33.291V31.4968C33.291 31.5552 33.3397 31.605 33.3981 31.605H35.515L35.5172 31.9987H33.2867Z" fill="white"/>
            <path d="M39.0031 30.5146C39.0031 30.5773 38.9523 30.6249 38.8907 30.6249L37.36 30.6282V30.1457L38.8907 30.149C38.9523 30.149 39.0031 30.1987 39.0031 30.2561V30.5146ZM39.5981 30.5146L39.5938 30.2561C39.5862 29.8115 39.1665 29.7552 39.0064 29.7552H36.7716V31.9987H37.3611L37.3579 31.0154L38.8917 31.0133C38.9534 31.0133 39.0042 31.0641 39.0042 31.1279V31.9998H39.5948V31.1279C39.5948 31.0089 39.5408 30.8921 39.4629 30.8305C39.5462 30.7601 39.6013 30.6509 39.5992 30.5157" fill="white"/>
            <path d="M42.0879 31.5055C42.0879 31.565 42.0338 31.617 41.9765 31.617H40.5627C40.5 31.617 40.4481 31.565 40.4481 31.5055V30.2464C40.4481 30.188 40.5 30.1361 40.5627 30.1361H41.9765C42.0349 30.1361 42.0879 30.1869 42.0879 30.2464V31.5055ZM42.6709 31.498V30.2519C42.6709 29.7792 42.2361 29.7543 42.076 29.7543H40.461C40.302 29.7543 39.8596 29.7781 39.8596 30.2519V31.498C39.8596 31.975 40.302 31.9999 40.461 31.9999H42.076C42.2361 31.9999 42.6709 31.975 42.6709 31.498Z" fill="white"/>
            <path d="M45.1682 30.5159C45.1682 30.5787 45.1184 30.6263 45.0535 30.6263L43.5262 30.6295V30.146L45.0535 30.1492C45.1173 30.1492 45.1682 30.2001 45.1682 30.2574V30.5159ZM45.7588 30.5159L45.7555 30.2574C45.7534 29.8118 45.3304 29.7566 45.1703 29.7566H42.9355V32H45.1703C45.3315 32 45.7555 31.9459 45.7555 31.4981V31.1271C45.7555 30.9995 45.7252 30.9054 45.6647 30.8307C45.7285 30.7561 45.7631 30.6533 45.7588 30.5159ZM45.1682 31.5046C45.1682 31.5609 45.1184 31.6128 45.0535 31.6128L43.5186 31.6074L43.5262 31.0135H45.0535C45.1173 31.0135 45.1682 31.0654 45.1682 31.1282V31.5046Z" fill="white"/>
            <path d="M48.2531 31.5055C48.2531 31.565 48.2011 31.617 48.1406 31.617H46.7289C46.6651 31.617 46.6143 31.565 46.6143 31.5055V30.2464C46.6143 30.188 46.6651 30.1361 46.7289 30.1361H48.1406C48.2011 30.1361 48.2531 30.1869 48.2531 30.2464V31.5055ZM48.8339 31.498V30.2519C48.8339 29.7792 48.4013 29.7543 48.2433 29.7543H46.624C46.4683 29.7543 46.0237 29.7781 46.0237 30.2519V31.498C46.0237 31.975 46.4683 31.9999 46.624 31.9999H48.2433C48.4002 31.9999 48.8339 31.975 48.8339 31.498Z" fill="white"/>
            <path d="M51.9164 30.146L50.8174 30.1503V31.999H50.2268V30.1503L49.1018 30.146V29.7566H51.9261L51.9164 30.146Z" fill="white"/>
            <path d="M53.7631 31.9987C53.5976 31.9987 53.179 31.9446 53.179 31.4968V30.2561C53.179 29.8115 53.5976 29.7552 53.7631 29.7552H56L55.9979 30.1457L53.8788 30.149C53.8182 30.149 53.7685 30.1987 53.7685 30.2561V30.6271H55.9979V31.0133H53.7642L53.7685 31.5022C53.7685 31.5595 53.8182 31.6104 53.8788 31.6104L55.9979 31.606L56 31.9976H53.7631V31.9987Z" fill="white"/>
            <path d="M58.4648 31.9984L57.7076 31.1645L56.9526 31.9984H56.2625L57.2933 30.8735L56.2657 29.7539H56.9753L57.7076 30.563L58.4399 29.7474H59.1441L58.1197 30.8735L59.1495 31.9984H58.4648Z" fill="white"/>
            <path d="M61.6394 31.9988V31.0134L60.0039 31.0155V31.9988H59.4111V29.7543L59.9974 29.7564L59.9996 30.625H61.6427L61.6394 29.7564L62.23 29.7543V31.9988H61.6394Z" fill="white"/>
            <path d="M63.0851 31.9988H62.4934V29.7543L63.0905 29.7564L63.0851 31.9988Z" fill="white"/>
            <path d="M65.5869 30.5159C65.5869 30.5754 65.535 30.6263 65.4722 30.6263L63.9405 30.6295V30.146L65.4722 30.1492C65.535 30.1492 65.5869 30.2001 65.5869 30.2574V30.5159ZM66.1786 30.5159L66.1764 30.2574C66.1677 29.8118 65.7491 29.7566 65.588 29.7566H63.3542V32H65.588C65.7491 32 66.1764 31.9459 66.1764 31.4981V31.1271C66.1764 30.9995 66.1418 30.9054 66.0823 30.8307C66.1461 30.7561 66.1818 30.6533 66.1775 30.5159M65.5858 31.5046C65.5858 31.5609 65.5339 31.6128 65.4711 31.6128L63.9362 31.6074L63.9394 31.0135H65.4711C65.5339 31.0135 65.5858 31.0654 65.5858 31.1282V31.5046Z" fill="white"/>
            <path d="M67.0372 31.9988H66.4434V29.7543L67.0437 29.7564L67.0372 31.9988Z" fill="white"/>
            <path d="M70.1225 30.146L69.0181 30.1503L69.0203 31.999H68.4275V30.1503L67.3069 30.146V29.7566H70.1258L70.1225 30.146Z" fill="white"/>
            <path d="M70.9879 31.9988H70.3918V29.7543L70.99 29.7564L70.9879 31.9988Z" fill="white"/>
            <path d="M73.4813 31.5055C73.4813 31.565 73.4316 31.617 73.3688 31.617H71.9551C71.8934 31.617 71.8393 31.565 71.8393 31.5055V30.2464C71.8393 30.188 71.8934 30.1361 71.9551 30.1361H73.3688C73.4316 30.1361 73.4813 30.1869 73.4813 30.2464V31.5055ZM74.0654 31.498V30.2519C74.0654 29.7792 73.6317 29.7543 73.4716 29.7543H71.8534C71.6954 29.7543 71.252 29.7781 71.252 30.2519V31.498C71.252 31.975 71.6944 31.9999 71.8534 31.9999H73.4716C73.6317 31.9999 74.0654 31.9739 74.0654 31.498Z" fill="white"/>
            <path d="M77.1351 31.9988H76.551L74.9674 30.187V31.9988H74.3281V29.7564H75.213L76.5077 31.2914V29.7564L77.1351 29.7543V31.9988Z" fill="white"/>
            <path d="M83.3093 2.574C83.3093 1.422 84.1769 0.554474 85.291 0.554474C86.4052 0.554474 87.2565 1.422 87.2565 2.574C87.2565 3.72601 86.39 4.60327 85.291 4.60327C84.192 4.60327 83.3093 3.74116 83.3093 2.574ZM85.291 5.0089C86.6172 5.0089 87.7454 3.97913 87.7454 2.574C87.7454 1.16888 86.6172 0.151001 85.291 0.151001C83.9649 0.151001 82.8193 1.17753 82.8193 2.574C82.8193 3.97047 83.9519 5.0089 85.291 5.0089ZM84.7761 2.76438H85.2661L86.0039 3.97913H86.4787L85.6837 2.74708C86.0936 2.69515 86.4073 2.47665 86.4073 1.97691C86.4073 1.422 86.0807 1.17753 85.4176 1.17753H84.351V3.97913H84.7761V2.76438ZM84.7761 2.40418V1.53774H85.3527C85.6501 1.53774 85.9638 1.60372 85.9638 1.94878C85.9638 2.38038 85.6447 2.40418 85.2845 2.40418H84.7761Z" fill="white"/>
            <path d="M70.4847 24.6304H66.4002C66.2899 24.6304 66.1785 24.7202 66.146 24.8306L65.749 26.306C65.7144 26.4163 65.7804 26.5083 65.8897 26.5083H69.1542C69.2646 26.5083 69.3533 26.5959 69.3533 26.7051V27.816C69.3533 27.9264 69.2646 28.0129 69.1542 28.0129L63.0545 28.0259C62.9399 28.0259 62.8739 27.9361 62.9074 27.8258L64.0724 23.5217C64.1049 23.4114 64.2163 23.3238 64.3288 23.3238H68.4208C68.5322 23.3238 68.6447 23.234 68.6794 23.1236L69.1218 21.4362C69.1445 21.3367 69.0882 21.2469 68.9768 21.2469H65.6831C65.5695 21.2469 65.4829 21.1604 65.4829 21.05V20.1371C65.4829 20.0278 65.5695 19.9261 65.6831 19.9261H71.7492C71.8617 19.9261 71.9374 20.0159 71.905 20.1252L70.7389 24.4411C70.7184 24.5417 70.5961 24.6315 70.4858 24.6315" fill="white"/>
            <path d="M70.5179 24.6087H66.4334C66.3231 24.6087 66.2117 24.6985 66.1792 24.8089L65.7822 26.2843C65.7476 26.3946 65.8136 26.4855 65.9229 26.4855H69.1874C69.2978 26.4855 69.3865 26.5731 69.3865 26.6824V27.7943C69.3865 27.9036 69.2978 27.9912 69.1874 27.9912L63.0877 28.0042C62.9731 28.0042 62.9071 27.9144 62.9406 27.8041L64.1056 23.5C64.1381 23.3897 64.2495 23.302 64.362 23.302H68.4551C68.5654 23.302 68.6779 23.2123 68.7126 23.1019L69.155 21.4156C69.1766 21.315 69.1214 21.2252 69.0089 21.2252H65.7173C65.6027 21.2252 65.5161 21.1387 65.5161 21.0283V20.1154C65.5161 20.0061 65.6027 19.9055 65.7173 19.9055H71.7824C71.8949 19.9055 71.9706 19.9953 71.9382 20.1035L70.7721 24.4194C70.7516 24.52 70.6293 24.6098 70.519 24.6098" fill="white"/>
            <path d="M81.2984 20.1068C81.3309 19.9975 81.2541 19.9088 81.1416 19.9088H75.0765C74.9619 19.9088 74.8753 20.0094 74.8753 20.1187V21.0316C74.8753 21.1419 74.9619 21.2285 75.0765 21.2285H78.3681C78.4806 21.2285 78.5347 21.3183 78.5142 21.4189L78.0707 23.1052C78.0404 23.2037 77.9441 23.2783 77.8446 23.2934H75.5644C75.4497 23.2934 75.3632 23.394 75.3632 23.5033V24.4162C75.3632 24.5266 75.4497 24.6131 75.5644 24.6131H77.386C77.5103 24.6131 77.6456 24.6694 77.5796 24.9095L77.2086 26.28C77.162 26.4033 77.069 26.4801 76.9598 26.4801H72.9434C72.8331 26.4801 72.7444 26.5677 72.7444 26.677V27.789C72.7444 27.8982 72.8331 27.9858 72.9434 27.9858L78.9587 27.9967C79.0702 27.9967 79.1924 27.9069 79.2129 27.8063L81.2974 20.1057L81.2984 20.1068Z" fill="white"/>
            <path d="M55.2271 19.9037H61.7303C61.8406 19.9037 61.9163 19.9924 61.885 20.1016L59.8103 27.8022C59.7875 27.9018 59.6675 27.9894 59.5517 27.9894H53.041C52.9307 27.9894 52.8744 27.9018 52.8982 27.8022L54.9707 20.1016C55.0075 19.9913 55.1179 19.9037 55.2282 19.9037M55.8815 26.4837H57.5484C57.6577 26.4837 57.768 26.3939 57.8026 26.2825L59.1126 21.4127C59.1439 21.3002 59.065 21.2223 58.9536 21.2223H57.2899C57.1796 21.2223 57.0703 21.3002 57.0335 21.4127L55.7258 26.2825C55.6922 26.3928 55.7723 26.4837 55.8815 26.4837Z" fill="white"/>
        </svg>
    );
};

export default LogoIrex;