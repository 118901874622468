import { useEffect, useRef } from "react";

import Scene from "../utils/Scene";

const Background = () => {
    const canvas = useRef(null);

    useEffect(() => {
        new Scene({ canvas: canvas.current });
    }, []);

    return (
        <canvas ref={canvas} className="fixed block w-screen h-screen top-0 left-0">
            123
        </canvas>
    );
};

export default Background;